<template>
  <div>
    <v-alert
      v-if="!roleCheck('userAdmin')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="alert-style"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="membersList"
        :search="search"
        disable-sort
        class="elevation-1 table-projects"
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.user.first_name }} {{ item.user.last_name }}</td>
            <td>
              <span v-if="item.user.phone_number !== null">{{ item.user.phone_number }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.user.email !== null">{{ item.user.email }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.user.organization !== null">{{ item.user.organization.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td class="drop-down-hover">
              <v-chip
                v-if="item.member_role !== null"
                outlined
                small
                class="ml-2"
                color="#37474F"
                light
              >
                {{ item.member_role.member_role }}
              </v-chip>
              <span v-else>N/A</span>
              <v-menu
                bottom
                left
                :offset-x="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="transparent"
                    class="button-visiblity"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    :disabled="isAccountUser(item.user.external_id, item.access_level)"
                    v-on="on"
                    @click="setUserValue(item)"
                  >
                    <v-icon color="black">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                </template>

                <v-list class="member-v-list">
                  <v-list-item
                    v-for="(item, i) in membersRolesList"
                    :key="i"
                    class="listing-buttons"
                    link
                  >
                    <v-list-item-content
                      @click="updateUserByMemberRole(item.id)"
                    >
                      <v-list-item-title>
                        {{ item.member_role }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td class="drop-down-hover">
              <span>{{ item.access_level | convertAccessLevel }}</span>
              <v-menu
                bottom
                right
                :offset-x="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="transparent"
                    class="button-visiblity"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    :disabled="isAccountUser(item.user.external_id, item.access_level)"
                    v-on="on"
                    @click="setUserValue(item)"
                  >
                    <v-icon color="black">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in filteredData"
                    :key="i"
                    class="listing-buttons"
                    link
                  >
                    <v-list-item-content @click="updateUserByAccessLevel(item.id)">
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                :disabled="deleteButtonCheck(item)"
                icon
                color="#C62828"
                @click="deleteMember(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner :loading="loading" />
    <delete-app-user
      v-if="showDelete"
      :title="'Delete this app user ?'"
      :message="'Action will permanently remove the  user from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import global from 'src/mixins/global';
import Constants from 'src/constants';

export default {
  name: 'ActiveMembersList',
  components: {
    'centre-spinner': spinner,
    'delete-app-user': DeleteDialog,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime)
        .local()
        .format('Do MMMM YYYY hh:mm A');
    },
    convertAccessLevel (val) {
      if (val === 'account_admin') {
        return 'Account Administrator';
      } else if (val === 'project_admin') {
        return 'Project Administrator';
      } else {
        return 'Project Member';
      }
    },
  },
  mixins: [global],
  data () {
    return {
      search: '',
      loading: false,
      showDelete: false,
      userId: '',
      userRole: '',
      selectedUserRole: null,
      selectedAccessLevel: null,
      headers: [
        { text: 'Name', align: 'start', value: 'user.first_name' },
        { text: 'Phone', value: 'phone_number' },
        { text: 'Email', value: 'email' },
        { text: 'Organization', value: 'organization.name' },
        { text: 'Member Role', value: 'member_role.name' },
        { text: 'Access Level', value: 'access_level' },
        { text: 'Added On', value: 'created_at' },
        { text: 'Delete', value: 'delete' },
      ],
      accessLevelList: [
        {
          name: 'Account Adminstrator',
          id: 'account_admin',
        },
        {
          name: 'Project Adminstrator',
          id: 'project_admin',
        },
        {
          name: 'Project Member',
          id: 'project_member',
        },
      ],
    };
  },
  computed: {
    membersList () {
      return this.$store.getters['members/getMembersList'];
    },
    membersRolesList () {
      return this.$store.getters['members/getMembersRolesList'];
    },
    userProfile () {
      return this.$store.getters['userprofile/getUserProfile'];
    },
    filteredData () {
      if (this.accessCheck('account_admin')) {
        return this.accessLevelList;
      } else if (this.accessCheck('project_admin')) {
        return this.accessLevelList.filter(item => item.id !== 'account_admin');
      } else {
        return [];
      }
    },
  },
  async mounted () {
    this.roleCheck('userAdmin') && (await this.getMembersList());
    await this.getUsersRoles();
  },
  methods: {
    async getMembersList () {
      this.loading = true;
      await this.$store.dispatch('members/fetchMembersList').catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getUsersRoles () {
      await this.$store.dispatch('members/fetchMemberRoles');
    },
    isAccountUser (externalId, accessLevel) {
      if (this.accessCheck('account_admin')) {
        if (externalId === this.userProfile.external_id) {
          return true;
        }
        return false;
      } else if (this.accessCheck('project_admin')) {
        if (externalId === this.userProfile.external_id || accessLevel === 'account_admin') {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    deleteButtonCheck (item) {
      if (item.database === false || this.isAccountUser(item.user.external_id, item.access_level) || !this.accessCheck('account_admin')) {
        return true;
      }
      return false;
    },
    setUserValue (data) {
      this.userId = data.id;
      this.selectedUserRole = data.member_role === null ? null : data.member_role.id;
      this.selectedAccessLevel = data.access_level;
    },
    async updateUserByAccessLevel (id) {
      this.loading = true;
      await this.$store
        .dispatch('members/updateMembersList', {
          updateDetails: {
            member_role_id: this.selectedUserRole,
            access_level: id,
          },
          userId: this.userId,
        })
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'User updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.getMembersList();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async updateUserByMemberRole (id) {
      this.loading = true;
      await this.$store
        .dispatch('members/updateMembersList', {
          updateDetails: {
            member_role_id: id,
            access_level: this.selectedAccessLevel,
          },
          userId: this.userId,
        })
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'User updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.getMembersList();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteMember (id) {
      this.userId = id;
      this.showDelete = true;
    },
    deleteConfirmed () {
      this.loading = true;
      this.$store
        .dispatch('members/deleteMember', {
          userId: this.userId,
        })
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Deleted user successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.getMembersList();
          this.showDelete = false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474f;
}

.icon-style {
  color: #37474f;
  font-size: 45px;
}

.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474f !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474f !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #cfd8dc;
}

.v-data-table {
  background-color: #eceff1;
}

.alert-style {
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
}

.v-list {
  padding: 0px 0px;
}

.listing-buttons .v-icon.v-icon {
  padding-right: 10px;
}

.drop-down-hover .button-visiblity {
  opacity: 0;
  visibility: hidden;
}

.drop-down-hover:hover .button-visiblity {
  opacity: 1;
  visibility: visible;
}

.member-v-list {
  max-height: 300px;
  overflow-y: auto;
}

.v-card {
  margin-top: 0px !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: transparent !important;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
